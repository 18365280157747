import { useEffect } from "react";

import {
  businessFilterPanelIsOpenStateConfiguration,
  businessFiltersConfiguration,
  dataViewAdvancedSearchPermanentFiltersConfiguration,
  dataViewElementsAuthorizedIconsConfiguration,
  dataViewElementsButtonsConfiguration,
  dataViewElementsElementIdDisplayRuleConfiguration,
  dataViewElementsLinksConfiguration,
  dataViewElementsThumbnailSizeConfiguration,
  dataViewElementsVisualAlertConfiguration,
  dataViewFieldsConfiguration,
  dataViewListSize,
  dataViewSortsConfiguration,
  useShare,
} from "@keepeek/commons";
import { useRouter } from "next/router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { businessFilterPanelIsOpenByConfigSelector } from "../../businessFilter/selectors/state";
import {
  configSectionComponentBusinessFilterSelector,
  configSectionComponentDataViewSelector,
  configSectionComponentDownloadManagerSelector,
  configSectionDataByPathSelector,
  configSectionGlobalPermanentFiltersSelector,
  configSectionPageSearchSelector,
  configSectionShareManagerSelector,
} from "../selectors";
import {
  businessFiltersConfigurationAdaptor,
  dataViewElementsAuthorizedIconsConfigurationAdaptor,
  dataViewElementsButtonsConfigurationAdaptor,
  dataViewElementsElementIdDisplayRuleConfigurationAdaptor,
  dataViewElementsLinksConfigurationAdaptor,
  dataViewElementsThumbnailSizeConfigurationAdaptor,
  dataViewElementsVisualAlertConfigurationAdaptor,
  dataViewFieldsConfigurationAdaptor,
  dataViewSortsConfigurationAdaptor,
  listViewSizeConfigurationAdaptor,
} from "../utils";

export const useInitDataViewConfiguration = () => {
  const router = useRouter();
  const { isShare } = useShare(router.pathname, router.query.tokenId);

  const setDataViewElementsAuthorizedIconsConfiguration = useSetRecoilState(
    dataViewElementsAuthorizedIconsConfiguration,
  );
  const setDataViewElementsThumbnailSizeConfiguration = useSetRecoilState(
    dataViewElementsThumbnailSizeConfiguration,
  );
  const setDataViewElementsElementIdDisplayRuleConfiguration = useSetRecoilState(
    dataViewElementsElementIdDisplayRuleConfiguration,
  );
  const setDataViewElementsVisualAlertConfiguration = useSetRecoilState(
    dataViewElementsVisualAlertConfiguration,
  );
  const setDataViewSortsConfiguration = useSetRecoilState(dataViewSortsConfiguration);
  const setDataViewFieldsConfiguration = useSetRecoilState(dataViewFieldsConfiguration);
  const setDataViewAdvancedSearchPermanentFiltersConfiguration = useSetRecoilState(
    dataViewAdvancedSearchPermanentFiltersConfiguration,
  );
  const setBusinessFiltersConfiguration = useSetRecoilState(businessFiltersConfiguration);

  const setDataViewElementsButtonsConfiguration = useSetRecoilState(
    dataViewElementsButtonsConfiguration,
  );
  const setDataViewElementsLinksConfiguration = useSetRecoilState(
    dataViewElementsLinksConfiguration,
  );
  const setListViewSize = useSetRecoilState(dataViewListSize);
  const setBusinessFilterPanelIsOpenStateConfiguration = useSetRecoilState(
    businessFilterPanelIsOpenStateConfiguration,
  );

  const businessFilterPanelIsOpenByConfig = useRecoilValue(
    businessFilterPanelIsOpenByConfigSelector,
  );
  const configSectionComponentDataView = useRecoilValue(configSectionComponentDataViewSelector);
  const configSectionComponentBusinessFilter = useRecoilValue(
    configSectionComponentBusinessFilterSelector,
  );
  const configSectionPageSearch = useRecoilValue(configSectionPageSearchSelector);
  const configSectionPageShare = useRecoilValue(
    configSectionDataByPathSelector(CONFIGURATION_SECTION_PATH.PAGES_SHARE),
  );
  const configSectionComponentDownloadManager = useRecoilValue(
    configSectionComponentDownloadManagerSelector,
  );
  const configSectionShareManager = useRecoilValue(configSectionShareManagerSelector);
  const configSectionGlobalPermanentFilters = useRecoilValue(
    configSectionGlobalPermanentFiltersSelector,
  );

  // INIT Dataview sorts configuration
  useEffect(() => {
    setDataViewSortsConfiguration(
      dataViewSortsConfigurationAdaptor(isShare, configSectionPageSearch, configSectionPageShare),
    );
  }, [configSectionPageSearch, configSectionPageShare, isShare, setDataViewSortsConfiguration]);

  // INIT Dataview elements visual alerts configuration
  useEffect(() => {
    setDataViewElementsVisualAlertConfiguration(
      dataViewElementsVisualAlertConfigurationAdaptor(
        isShare,
        configSectionPageSearch,
        configSectionPageShare,
      ),
    );
  }, [
    configSectionPageSearch,
    configSectionPageShare,
    isShare,
    setDataViewElementsVisualAlertConfiguration,
  ]);

  // INIT Dataview elements element ID display rule configuration
  useEffect(() => {
    setDataViewElementsElementIdDisplayRuleConfiguration(
      dataViewElementsElementIdDisplayRuleConfigurationAdaptor(
        isShare,
        configSectionPageSearch,
        configSectionPageShare,
      ),
    );
  }, [
    configSectionPageSearch,
    configSectionPageShare,
    isShare,
    setDataViewElementsElementIdDisplayRuleConfiguration,
  ]);

  // INIT Dataview elements authorized icons configuration
  useEffect(() => {
    setDataViewElementsAuthorizedIconsConfiguration(
      dataViewElementsAuthorizedIconsConfigurationAdaptor(configSectionComponentDataView),
    );
  }, [configSectionComponentDataView, setDataViewElementsAuthorizedIconsConfiguration]);

  // INIT Dataview elements thumbnail size configuration
  useEffect(() => {
    setDataViewElementsThumbnailSizeConfiguration(
      dataViewElementsThumbnailSizeConfigurationAdaptor(configSectionComponentDataView),
    );
  }, [configSectionComponentDataView, setDataViewElementsThumbnailSizeConfiguration]);

  // INIT Dataview links configuration
  useEffect(() => {
    setDataViewElementsLinksConfiguration(
      dataViewElementsLinksConfigurationAdaptor(
        isShare,
        configSectionPageSearch,
        configSectionPageShare,
        configSectionComponentDownloadManager,
      ),
    );
  }, [
    configSectionComponentDownloadManager,
    configSectionPageSearch,
    configSectionPageShare,
    isShare,
    setDataViewElementsLinksConfiguration,
  ]);

  // INIT Dataview buttons configuration
  useEffect(() => {
    setDataViewElementsButtonsConfiguration(
      dataViewElementsButtonsConfigurationAdaptor(
        isShare,
        configSectionPageSearch,
        configSectionPageShare,
        configSectionShareManager,
      ),
    );
  }, [
    configSectionPageSearch,
    configSectionPageShare,
    configSectionShareManager,
    isShare,
    setDataViewElementsButtonsConfiguration,
  ]);

  // INIT Dataview fields configuration
  useEffect(() => {
    if (configSectionPageSearch) {
      setDataViewFieldsConfiguration(
        dataViewFieldsConfigurationAdaptor(
          isShare,
          configSectionPageSearch,
          configSectionPageShare,
          configSectionComponentDownloadManager,
        ),
      );
    }
  }, [
    configSectionComponentDownloadManager,
    configSectionPageSearch,
    configSectionPageShare,
    isShare,
    setDataViewFieldsConfiguration,
  ]);

  // INIT Dataview advanced search permanent filters configuration
  useEffect(() => {
    // Set valid permanent filters only: public permanent filters configuration is not valid
    // and contains empty objects array
    if (
      !configSectionGlobalPermanentFilters ||
      (configSectionGlobalPermanentFilters.length > 0 &&
        !configSectionGlobalPermanentFilters[0].internalFieldName)
    ) {
      return;
    }
    setDataViewAdvancedSearchPermanentFiltersConfiguration(configSectionGlobalPermanentFilters);
  }, [configSectionGlobalPermanentFilters, setDataViewAdvancedSearchPermanentFiltersConfiguration]);

  // INIT Dataview business filter configuration
  useEffect(() => {
    if (configSectionComponentBusinessFilter?.filters) {
      setBusinessFiltersConfiguration(
        businessFiltersConfigurationAdaptor(configSectionComponentBusinessFilter.filters) ||
          undefined,
      );
    }
  }, [configSectionComponentBusinessFilter?.filters, setBusinessFiltersConfiguration]);

  // INIT List dataview size
  useEffect(() => {
    setListViewSize(listViewSizeConfigurationAdaptor(configSectionComponentDataView));
  }, [configSectionComponentDataView, setListViewSize]);

  // INIT Business filter first display open state
  useEffect(() => {
    setBusinessFilterPanelIsOpenStateConfiguration(businessFilterPanelIsOpenByConfig);
  }, [
    businessFilterPanelIsOpenByConfig,
    configSectionComponentDataView,
    setBusinessFilterPanelIsOpenStateConfiguration,
  ]);
};
